var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"list-none border-b accordion-item",class:{'pt-4': !_vm.transparentAccHeader},attrs:{"id":_vm.id}},[_c('div',{staticClass:"relative"},[_c('button',{staticClass:"z-10 flex items-center justify-between w-full gap-4 font-bold transition-all duration-300 text-700 ",class:{
        'h-16 px-4 bg-white rounded': _vm.transparentAccHeader,
        'absolute top-0 bg-opacity-50': _vm.transparentAccHeader && _vm.isOpen,
        'hover:text-petrol-300 hover:fill-petrol-300': _vm.allowClose || !_vm.isOpen,
        'cursor-default': !_vm.allowClose && _vm.isOpen
      },on:{"click":_vm.toggleIsOpen}},[_c('span',{staticClass:"w-full text-left"},[_vm._t("title"),_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")],2),_vm._v(" "),(_vm.allowClose || !_vm.isOpen)?_c('Icon',{staticClass:"shrink-0 w-5 h-5 transition-all duration-300 ease-out",class:[_vm.isOpen ? 'transform rotate-180' : null],attrs:{"icon":"IconChevronDown"}}):_vm._e()],1),_vm._v(" "),_c('div',{ref:"accContent",staticClass:"z-0 overflow-hidden transition-all duration-300 ease-out accordion-item-content",class:[
        _vm.isOpen ? 'opacity-100' : 'opacity-0 max-h-0',
        !_vm.transparentAccHeader ? 'mt-4' : ''
      ]},[(_vm.transparentAccHeader)?_c('div',{staticClass:"absolute z-20 w-full h-4 opacity-50 top-16 bg-gradient-to-t from-transparent to-white"}):_vm._e(),_vm._v(" "),_vm._l((_vm.content),function(item,index){return _c('NuxtDynamic',_vm._b({key:index,staticClass:"mb-8 md:mb-8",attrs:{"name":item.component}},'NuxtDynamic',item,false))}),_vm._v(" "),_vm._t("content")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }