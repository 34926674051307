//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'ImageLink',
  props: {
    image: {
      type: Object,
      default: () => {}
    },
    link: {
      type: String,
      default: ''
    },
    imageStyle: {
      type: String,
      default: 'w-full'
    },
    blank: {
      type: Boolean,
      default: false
    }
  }
})
