//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FooterEntry',
  props: {
    headline: {
      type: String,
      default: ''
    },
    links: {
      type: Array,
      default: () => []
    }
  }
}
