//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, useRoute, onMounted, watch } from '@nuxtjs/composition-api'
import { useElementVisibility } from '@vueuse/core'

export default defineComponent({
  name: 'AccordionItem',
  props: {
    id: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    content: {
      type: Array,
      default: () => []
    },
    open: {
      type: Boolean,
      default: false
    },
    transparentAccHeader: {
      type: Boolean,
      dafault: false
    },
    allowClose: {
      type: Boolean,
      default: true
    }
  },
  emits: ['input'],
  setup (props, { emit }) {
    const route = useRoute()
    const isOpen = ref(false)
    const accContent = ref(null)
    const accContentIsVisible = useElementVisibility(accContent)

    onMounted(() => {
      // Check if Item ID is inside slug
      isOpen.value = props.open || (route.value.hash.substring(1) === props.id && !!props.id)
      emit('input', isOpen.value)
    })

    watch(() => route.value.query, () => {
      if (route.value.query.tabItem) {
        const queryItem = route.value.query.tabItem
        if (queryItem === props.id) {
          isOpen.value = true
        }
        if (!accContentIsVisible.value && isOpen.value) {
          accContent.value.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }
    })

    watch(() => route.value.hash, () => {
      if (route.value.hash === `#${props.id}`) {
        isOpen.value = true
        emit('input', isOpen.value)
      }
    })

    function toggleIsOpen () {
      isOpen.value = (!props.allowClose || !isOpen.value)
      emit('input', isOpen.value)
    }

    return {
      isOpen,
      accContent,
      toggleIsOpen
    }
  }
})
